import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import AccordionComponent from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS API Gateway",
  "path": "/knowledge/tech/aws",
  "date": "2023-01-15T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws-api-gateway",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws-api-gateway",
            "aria-label": "aws api gateway permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS API Gateway`}</h1>
        <h2 {...{
          "id": "whats-aws-api-gateway",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#whats-aws-api-gateway",
            "aria-label": "whats aws api gateway permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`What's AWS API Gateway?`}</h2>
        <p>{`Amazon Web Services (AWS) API Gateway is a fully managed service that makes it easy for developers to create, publish, maintain, monitor, and secure APIs at any scale. It acts as a "front door" for applications to access data, business logic, or functionality from your back-end services.`}</p>
        <AccordionComponent summaryText="View Diagram" mdxType="AccordionComponent">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/b79a5/image-19.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "118.00000000000001%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB2ElEQVQ4y5WV2a7CMAxE+/9/xwNPSCBRdsq+r0HH0lTGDVe9D1HSJB7b43FaPJ/PpPF4POr5fr/b/Hq9bK1vjXhf68Jf0ublcrH1drtN4/HYwNiLoDnbn4CbzSYNBoPU6/XSbDZL5/O5PWBM+Xa7WXSLxSItl8u0Wq3S6XRqGOdGkfNEJD51XYbPyDPOr9erzV8R+susBSgwjI7Ho+3JEbOAtC4w3u/3liIHeN3tdmk0GqWqqmpj9obDYep2u8atigQV8/nc7uC0EGfwNJlMDBggjMqytKgoCJfX63XqdDq2r3QJhqLVEYoPgDAgEr45xDsGnksyEiU44gxnrA1QhXi/3/VlvgE7HA4WuZx4wcciNmST0yIeceQj0GjdKToAxLcfgIidAQ3/6hSRjagBVhTQQPpe5NkIY6cAIg6psoQeO6R1ypKEugBw1ipYq8cheow9SrXRJ4OoYxv+GWHOM0WBP4ABjMqoAb1Yfz2cGkgJQArmi/UFiGfI13uXA/LVh08iFKeNlHnz+v2+NbgXbBycwR/OiVDyUVt+ceirF2WhPRzyMEynUwMhSnqfzLxd4XsxN2stTRIde3Cot9Hfa1Vl/9IAoF8D0TU4/KX8eNHLh/dPP65Y5Q/9s1J99V84FQAAAABJRU5ErkJggg==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "design of API Gateway request",
                  "title": "design of API Gateway request",
                  "src": "/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/c1b63/image-19.png",
                  "srcSet": ["/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/5a46d/image-19.png 300w", "/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/0a47e/image-19.png 600w", "/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/c1b63/image-19.png 1200w", "/static/dd75cb6b8d4f6a0fd0a17adb30ce4a5e/b79a5/image-19.png 1372w"],
                  "sizes": "(max-width: 1200px) 100vw, 1200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </AccordionComponent>
        <h2 {...{
          "id": "aws-api-gateway-vs-expressjs-api-gateway",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#aws-api-gateway-vs-expressjs-api-gateway",
            "aria-label": "aws api gateway vs expressjs api gateway permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS API Gateway vs Express.js API Gateway`}</h2>
        <h3 {...{
          "id": "expressjs",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#expressjs",
            "aria-label": "expressjs permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Express.js`}</h3>
        <p>{`An Express app could act as an API gateway, but it would require you to handle the management, scaling, and security of the API yourself, whereas with AWS API Gateway, it handles these things for you.`}</p>
        <h4 {...{
          "id": "logging",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#logging",
            "aria-label": "logging permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Logging`}</h4>
        <p>{`With an express.js app, you would have to manually set up logging for your API, whereas with AWS API Gateway, you can automatically log API requests and responses using Amazon CloudWatch.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import winston from 'winston'
import expressWinston from 'express-winston'
import responseTime from 'response-time'

// middleware for logging requests and how fast they get responded to
app.use(
  expressWinston.logger({
    transports: [new winston.transports.Console()],
    format: winston.format.json(),
    statusLevels: true,
    meta: false,
    msg: 'HTTP {{req.method}} {{req.url}} {{res.statusCode}} {{res.responseTime}}ms',
    expressFormat: true,
    ignoreRoute() {
      return false
    },
  })
)

// to forward requests to other microservices (APIs)
const { createProxyMiddleware } = require('http-proxy-middleware')

app.use(
  '/search',
  createProxyMiddleware({
    target: 'http://api.duckduckgo.com/',
    changeOrigin: true,
    pathRewrite: {
      ['^/search']: '',
    },
  })
)

// ... some other proxy middlewares for other microservices
`}</code></pre>
        <h4 {...{
          "id": "authentication",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#authentication",
            "aria-label": "authentication permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Authentication`}</h4>
        <p>{`With an express.js app, you would have to implement authentication and authorization mechanisms on your own, whereas with AWS API Gateway, you can easily integrate with AWS Identity and Access Management (IAM) for authentication and authorization.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`require('dotenv').config()

const session = require('express-session')

const secret = process.env.SESSION_SECRET
const store = new session.MemoryStore()
const protect = (req, res, next) => {
  const { authenticated } = req.session

  if (!authenticated) {
    res.sendStatus(401)
  } else {
    next()
  }
}

app.use(
  session({
    secret,
    resave: false,
    saveUninitialized: true,
    store,
  })
)

app.get('/login', (req, res) => {
  const { authenticated } = req.session

  if (!authenticated) {
    req.session.authenticated = true
    res.send('Successfully authenticated')
  } else {
    res.send('Already authenticated')
  }
})

app.get('/logout', protect, (req, res) => {
  req.session.destroy(() => {
    res.send('Successfully logged out')
  })
})

app.get('/protected', protect, (req, res) => {
  const { name = 'user' } = req.query
  res.send(\`Hello \${name}!\`)
})
`}</code></pre>
        <h4 {...{
          "id": "rate-limiting",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#rate-limiting",
            "aria-label": "rate limiting permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Rate limiting`}</h4>
        <p>{`With an express.js app, you would have to implement rate limiting on your own, whereas with AWS API Gateway, you can easily configure rate limits on your API.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import rateLimit from 'express-rate-limit'

// rate limiting
const limiter = rateLimit({
  windowMs: 15 * 60 * 1000, // 15 minutes
  max: 100, // Limit each IP to 100 requests per \`window\` (here, per 15 minutes)
})

// or app.use('/api', limiter)
app.use(limiter)

// ...
`}</code></pre>
        <AccordionComponent iconColor="warning" summaryColor="#ed6c02" summaryText="Inaccurate: scaling" mdxType="AccordionComponent">
          <h4 {...{
            "id": "scaling",
            "style": {
              "position": "relative"
            }
          }}><a parentName="h4" {...{
              "href": "#scaling",
              "aria-label": "scaling permalink",
              "className": "anchor before"
            }}><svg parentName="a" {...{
                "aria-hidden": "true",
                "focusable": "false",
                "height": "16",
                "version": "1.1",
                "viewBox": "0 0 16 16",
                "width": "16"
              }}><path parentName="svg" {...{
                  "fillRule": "evenodd",
                  "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                }}></path></svg></a>{`Scaling`}</h4>
          <p>{`With an express.js app, you would have to manually scale your API by spinning up additional servers and managing load balancers, whereas with AWS API Gateway, you can easily scale your API by adjusting the number of API Gateway instances.`}</p>
          <p>{`In a traditional Express.js app, this is done with the `}<inlineCode parentName="p">{`cluser`}</inlineCode>{` module.`}</p>
          <pre><code parentName="pre" {...{
              "className": "language-js"
            }}>{`const express = require('express')
const cluster = require('cluster')
const os = require('os')

// checks if the cluster is the master one, if it's
// it starts all the workers
if (cluster.isMaster) {
  // get the number of CPU cores
  const numCores = os.cpus().length

  // start a worker for each core
  for (let i = 0; i < numCores; i++) {
    cluster.fork()
  }

  // listen for workers that exit and restart them
  cluster.on('exit', (worker) => {
    console.log(\`worker \${worker.id} died. restarting...\`)
    cluster.fork()
  })
} else {
  // every time a worker is created, it'll execute this exact file
  // with (cluster.fork)
  // and since it's not the master cluster, it'll run this \`else\` block

  // create the Express app
  const app = express()

  // add routes
  app.get('/', (req, res) => {
    res.send('Hello, World!')
  })

  // start the server
  const port = 3000
  app.listen(port, () => {
    console.log(\`Worker \${cluster.worker.id} listening on port \${port}\`)
  })
}
`}</code></pre>
        </AccordionComponent>
        <h3 {...{
          "id": "aws-api-gateway-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-api-gateway-1",
            "aria-label": "aws api gateway 1 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS API Gateway`}</h3>
        <h5 {...{
          "id": "other-features",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#other-features",
            "aria-label": "other features permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Other features`}</h5>
        <ul>
          <li parentName="ul">{`Integration with `}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-control-access-aws-waf.html"
            }}>{`AWS WAF`}</a>{` for protecting your APIs against common web exploits.`}</li>
          <li parentName="ul">{`Integration with `}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-xray.html"
            }}>{`AWS X-Ray`}</a>{` for understanding and triaging performance latencies.`}</li>
        </ul>
        <h5 {...{
          "id": "some-important-concepts",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#some-important-concepts",
            "aria-label": "some important concepts permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Some important concepts`}</h5>
        <ul>
          <li parentName="ul">{`API key: An alphanumeric string that API Gateway uses to identify an app developer who uses your REST or WebSocket API.`}</li>
        </ul>
        <h4 {...{
          "id": "rest-apis-vs-http-apis",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#rest-apis-vs-http-apis",
            "aria-label": "rest apis vs http apis permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`REST APIs vs HTTP APIs`}</h4>
        <AccordionComponent summaryText="Exclusive features for each API type" mdxType="AccordionComponent">
          <ul>
            <li parentName="ul">{`REST API`}
              <ul parentName="li">
                <li parentName="ul">{`API keys`}</li>
                <li parentName="ul">{`Per-client rate-limiting`}</li>
                <li parentName="ul">{`Per-client usage throttling`}</li>
                <li parentName="ul">{`Resource policies (who can invoke the API)`}</li>
                <li parentName="ul">{`Certificates for backend auth`}
                  <ul parentName="li">
                    <li parentName="ul">{`You can use this certificate to verify that incoming requests to your backend are from API Gateway, ensuring that only authorized requests are accepted. This improves the security of your backend system, even if it is publicly accessible.`}</li>
                  </ul>
                </li>
                <li parentName="ul">{`AWS WAF(web exploits protection)`}</li>
                <li parentName="ul">{`Edge-optimized`}
                  <ul parentName="li">
                    <li parentName="ul">{`This means that an API that is designed to have low latency and high availability. This is achieved by having the API endpoint located at the edge of the network, closer to the users that are accessing it.`}</li>
                  </ul>
                </li>
                <li parentName="ul">{`Private (second type of REST API)`}
                  <ul parentName="li">
                    <li parentName="ul">{`A private endpoint in AWS refers to an endpoint that is only accessible within a virtual private cloud (VPC) or over an AWS Direct Connect link and not over the Internet. This means that the endpoint can only be accessed by resources within the same VPC or via a dedicated network connection, rather than over the public internet. This can be useful for situations where you want to keep certain resources or data private and only accessible by authorized users or systems.`}</li>
                    <li parentName="ul">{`E.g, a school campus or a bank`}</li>
                  </ul>
                </li>
                <li parentName="ul">{`Request caching`}</li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-xray.html"
                  }}>{`AWS X-Ray tracing`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/set-up-logging.html"
                  }}>{`Execution logs`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-logging-to-kinesis.html"
                  }}>{`Access logs to Amazon Kinesis Data Firehose`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-develop-integrations-private.html"
                  }}>{`Private integrations with Application Load Balancers`}</a></li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-develop-integrations-private.html"
                  }}>{`Private integrations with AWS Cloud Map`}</a></li>
              </ul>
            </li>
            <li parentName="ul">{`HTTP API`}
              <ul parentName="li">
                <li parentName="ul">{`Automatic deployments`}</li>
                <li parentName="ul">{`JWT`}</li>
              </ul>
            </li>
            <li parentName="ul">{`WebSocket API`}</li>
          </ul>
        </AccordionComponent>
        <p>{`The major difference, an HTTP API only works with Lambda and HTTP backends (some Express.js API), while a REST API works with these too, and other AWS services.`}</p>
        <p>{`Usually, an HTTP API is sufficient for Lambda function or HTTP backends as it costs less and provides lower latency.`}</p>
        <h4 {...{
          "id": "creating-an-http-api-for-pokedex-with-http-backend",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#creating-an-http-api-for-pokedex-with-http-backend",
            "aria-label": "creating an http api for pokedex with http backend permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating an HTTP API for Pokedex with HTTP backend`}</h4>
        <p>{`First, we go to the AWS API Gateway panel and select an HTTP API. The page `}<inlineCode parentName="p">{`Create and configure integrations`}</inlineCode>{` will show up:`}</p>
        <p><strong parentName="p">{`Integrations`}</strong>{`: Type of the integration whether it's a Lambda function or HTTP endpoint`}</p>
        <p><strong parentName="p">{`Method`}</strong>{`: Request method `}<inlineCode parentName="p">{`GET`}</inlineCode>{`, `}<inlineCode parentName="p">{`POST`}</inlineCode>{`, ...etc`}</p>
        <p><strong parentName="p">{`URL Endpoint`}</strong>{`: In our case, it's `}<a parentName="p" {...{
            "href": "https://pokeapi.co/api/v2/pokemon-form/pikachu"
          }}>{`https://pokeapi.co/api/v2/pokemon-form/pikachu`}</a></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/abcc6b6611d1eefc72e0937d218d79a3/8bb81/image-1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "70.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABu0lEQVQ4y6VUi26jMBDk/3+vupaQtCltciFgngH8wDCnWQIKbXU66SyNdrG9u7OzToK6rpHnOYqigFI5lFLIiwKZUijLCl3fC/o7ui929bVG23UImqZBHH/gfP6Nz88T4jgW//XtDUlyxTSNmKYJ4zgKFv+nPW0MgrZtURQl8rwQlmVViWUhYZplyDIlIHt2wT0WY2eMS9MMXdfDDQMCBj89/cLh8IowDPH8/IIw3K3Y7SKEuwi7KJLvKNrPezyL9tjvD/LN5AMTGmvR9xpaa1g3wPvv7awYp833cr4sSXhrWxzfYySXC1TTQTu/CfDew3+13q9nzg3SrtZmbpnMqNsoSWZW1joYY6Qi60uBB8u7TGaMlUFc0wx13cwM6ZxOZ6HMi6zCYVC34/FdROdAKDz3afmcFkmIZVlrEbBCc7vd0QozYencyoBvjJ2I3tTa2k3CRQLGBAxiy5fkijRTosm/rM2w7oORlp1zm0n9z5KhVHUtGhZlBU6cbXNqfErdt5+a3ljeu7WdxLEzYcjD5JrOk2oaAXXiIdnzohP/wd798eEZscnBDwhYafkjoKgyDGv/DjNbKfoAvsU/FVA7f0VU8E4AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create and configure integrations",
                "title": "create and configure integrations",
                "src": "/static/abcc6b6611d1eefc72e0937d218d79a3/c1b63/image-1.png",
                "srcSet": ["/static/abcc6b6611d1eefc72e0937d218d79a3/5a46d/image-1.png 300w", "/static/abcc6b6611d1eefc72e0937d218d79a3/0a47e/image-1.png 600w", "/static/abcc6b6611d1eefc72e0937d218d79a3/c1b63/image-1.png 1200w", "/static/abcc6b6611d1eefc72e0937d218d79a3/8bb81/image-1.png 1602w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Next, Configure the routes page. We choose what resource path and method that when are requests, will target `}<a parentName="p" {...{
            "href": "https://pokeapi.co/api/v2/pokemon-form/pikachu"
          }}>{`https://pokeapi.co/api/v2/pokemon-form/pikachu`}</a></p>
        <p>{`We can set the resource path to anything, so I set it to `}<inlineCode parentName="p">{`/pokedex`}</inlineCode>{` and the method is `}<inlineCode parentName="p">{`GET`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`ANY`}</inlineCode>{` even though `}<inlineCode parentName="p">{`ANY`}</inlineCode>{` would've worked.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f2c5dfe69ba715a934df637ca64cfe64/7575b/image-2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "43.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABT0lEQVQoz41S2W6DQAzk/78u5OLsQ0lSBdgLWJabqWxEm+aplkYerXdtz4BX1TVKIaC0hlIKpm5g2xatc2hbt+X/om3hSaWQph+43x/IsgyFadBPC7CuWJaFsa5/M/Pll2+1FeM4wqPOxhhorWGqirmUEkVRIi8K0EDiQkqUpWA1jFJAiA3E87yAcx28x+MLh4OP4/HEOJ8v8I8n+P4JSZLifLkiTlLEccI8CEJEUYwwjHANQgRhhMs1YIXTNMGz1kIpzZMoa23Yy77vWcY8zyzpNdYXO17ry7rCa5qGG1HMy8LStTHsx/6Ygqxg6UKi6/uthr9BzT3yTUiFfhjgnMNndsPzmWMYR4zTxI1pA/oLqJbdbiCbyLPGWm7+zAsUpcAwjPC6rkMpJDfd19+3eo9dKt0j7Pf2d5tka2GqmkFfnDYl0OR37LUfvJ27rsM3d9+yxDSwzpEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "setting resource path",
                "title": "setting resource path",
                "src": "/static/f2c5dfe69ba715a934df637ca64cfe64/c1b63/image-2.png",
                "srcSet": ["/static/f2c5dfe69ba715a934df637ca64cfe64/5a46d/image-2.png 300w", "/static/f2c5dfe69ba715a934df637ca64cfe64/0a47e/image-2.png 600w", "/static/f2c5dfe69ba715a934df637ca64cfe64/c1b63/image-2.png 1200w", "/static/f2c5dfe69ba715a934df637ca64cfe64/7575b/image-2.png 1608w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Next, Configure stages. We can use the default stage name here, and with auto-deploy on.`}</p>
        <blockquote>
          <p parentName="blockquote">{`Stages are associated with a specific deployment of an API. A deployment is a snapshot of an API's configuration and resources at a given point in time. You can create multiple deployment stages, each with its settings, and then use them to test different versions of your API before promoting them to production. They're like GitHub branches.`}</p>
        </blockquote>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c0ce57368ae599c16437c8929a3e5aeb/8bb81/image-3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "44.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABNklEQVQoz42Ri26DMAxF8///trUQpk6d1Eml0tqEJDwCJCRwp5ite6jVhnRlc2PsE8yatoVSGtoYKK1Rty06a2H7/r7svdyCaW2w37/gWJY4HA4QpoMLM7DMmOfbWr7ny2dc4JwD6zqLpmlhEqHSMFpDCAEpKyKuqgpKKWitKVaVghCS6jXVSrqdlBLOebDz+YLNJsPD4wbbLEeeF8h5gSznyJN4ge02ozPOC/LSO+dPH/Ucu90zytMJ3k9g/TBASHmdnojquibaixDkJVoiVmtuTE2E6eyLztHVWfqRqUFq/N/HO0eD+/7nNyEEsLSdY3mihmlCMkOMa7yhGCNeZYvGDohx9d7OFyKlhuPoyJCVwhQCbesvhXm5Uq3b9ddhrLM9TN3QRhOl9x7uD/2umaaJNIwj3gEjN7GK3RBr0wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "stages",
                "title": "stages",
                "src": "/static/c0ce57368ae599c16437c8929a3e5aeb/c1b63/image-3.png",
                "srcSet": ["/static/c0ce57368ae599c16437c8929a3e5aeb/5a46d/image-3.png 300w", "/static/c0ce57368ae599c16437c8929a3e5aeb/0a47e/image-3.png 600w", "/static/c0ce57368ae599c16437c8929a3e5aeb/c1b63/image-3.png 1200w", "/static/c0ce57368ae599c16437c8929a3e5aeb/8bb81/image-3.png 1602w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Once this step is done, we create the API Gateway and we get this link `}<a parentName="p" {...{
            "href": "https://7m3eb19o5m.execute-api.us-east-1.amazonaws.com/pokedex"
          }}>{`https://7m3eb19o5m.execute-api.us-east-1.amazonaws.com/pokedex`}</a>{` that once accessed, redirects our request to pokeAPI and returns the response from it.`}</p>
        <h4 {...{
          "id": "creating-a-rest-api-for-pokedex-with-a-lambda-function",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#creating-a-rest-api-for-pokedex-with-a-lambda-function",
            "aria-label": "creating a rest api for pokedex with a lambda function permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating a REST API for Pokedex with a Lambda function`}</h4>
        <p>{`For this project, we'll create a Lambda function written in JavaScript that sends a request to `}<a parentName="p" {...{
            "href": "https://pokeapi.co/api/v2/pokemon-form/pikachu"
          }}>{`PokeAPI`}</a>{` and return the response.`}</p>
        <p>{`Our Lambda function will have the following code:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`export const handler = async (event) => {
  const res = await global.fetch(
    'https://pokeapi.co/api/v2/pokemon-form/pikachu'
  )
  return {
    statusCode: 200, // essential
    body: await res.json(),
  }
}
`}</code></pre>
        <p><strong parentName="p">{`Info`}</strong>{`: It's essential to return at least a `}<inlineCode parentName="p">{`statusCode`}</inlineCode>{` when Integrating a Lambda Function with AWS API Gateway`}</p>
        <p>{`To be able to call the lambda function from an endpoint, we'll create a new REST API gateway for it.`}</p>
        <h5 {...{
          "id": "choosing-an-api-type",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#choosing-an-api-type",
            "aria-label": "choosing an api type permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Choosing an API type`}</h5>
        <p>{`As we discussed in the REST APIs vs HTTP APIs section, there are 4 types of APIs that we can choose from. We'll pick a public REST API.`}</p>
        <p>{`In Amazon API Gateway after hitting `}<inlineCode parentName="p">{`Create an API`}</inlineCode>{`, we'll find this page:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/460cf2b707e1526c069a605bbeddffc8/8711f/image-4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "78.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB1ElEQVQ4y4VU2W7bMBDU/39LHtrPCNKgyHN65JRkS6pIijdFTbGrI7JdNwQGFHbJ4e541kXfCxyPDUIIsM7hvSzx+vaG55dX9EJAKgUhFYRaINUcWzDn5YZCqYEvEmGMEW3XoWkaGGOQUkJMifc99rEYE8ZxRM4jcxTOOTjnkXNGiBFUcVlWXLUQAsMwYBj0tmtjQEVovcYGvksrTUCB3fIhcGV0WAjJL3+2iMz5gPHpDr5+RDFNEwgbobVb+5FbiteREpz30MbC/ryFLs8IuWUhUFUV+r6H9x7WOsYqDRHM3xSfH2cOAHHMc8t7QiklDocjqqpmDf1CMO8r3IYQ4tZ+Ak4JZw0tVzRoDe8D1vwFKI75m34S//AV9ve3fxEa3secF1tEtsaHpulD28VCrGX7AtMfTgnpUNf9YQ3ZImQNtofmh04stNhGKcVacssZ5xoGSKnQNC3KqubDZAuyz//APkweKbjLlkl8OkTVUst0+BroHt1R2sJ9/wLz6+6sQkou7ezHbNWM8qeITEhWCs7AGn1pG7JKXR94BNfxop0s8tnaRm9fIf17tG3HpDSzlNt0ujJ6KY2YdIuo+0sN3aLh2vJe/HQGipHRSUN7fwPz4xZ/AfxV36mb2XcKAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create api",
                "title": "create api",
                "src": "/static/460cf2b707e1526c069a605bbeddffc8/c1b63/image-4.png",
                "srcSet": ["/static/460cf2b707e1526c069a605bbeddffc8/5a46d/image-4.png 300w", "/static/460cf2b707e1526c069a605bbeddffc8/0a47e/image-4.png 600w", "/static/460cf2b707e1526c069a605bbeddffc8/c1b63/image-4.png 1200w", "/static/460cf2b707e1526c069a605bbeddffc8/d61c2/image-4.png 1800w", "/static/460cf2b707e1526c069a605bbeddffc8/8711f/image-4.png 2214w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`We chose our API to be REST instead of Websocket, and create it from scratch. After creating the API, we'll land on this page for configuring the API methods, resources, authorizers, ...etc:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9edf85caf0d8031645dced6cdc60fb7d/50f29/image-5.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.333333333333336%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAyUlEQVQoz3VRiw6DIAzk/z9zJovb2JSHVkC85UAXR7YmTUt7vT5Q8zyDKrLAToLROFhrYEzVEAJijMg5Y9u2jz0rY8TQVyJSihhcYkJIGTGtBUDCh37iNYyYJl8wFBb+EzXbF5K4/bkDm4K+72Gt/ZCdtY0prseVKfmU4OT1FNVy4l/THW/WUpX3U+m+rutXp5QStNbouq7k28kOfNtEee/Lrc6Jw47jgPvjCeelnKP9iOpXoosWXIcFyjkHkraE/1Zr5YiT7GYC3hkKJp2s4As9AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "rest-api gateway console",
                "title": "rest-api gateway console",
                "src": "/static/9edf85caf0d8031645dced6cdc60fb7d/c1b63/image-5.png",
                "srcSet": ["/static/9edf85caf0d8031645dced6cdc60fb7d/5a46d/image-5.png 300w", "/static/9edf85caf0d8031645dced6cdc60fb7d/0a47e/image-5.png 600w", "/static/9edf85caf0d8031645dced6cdc60fb7d/c1b63/image-5.png 1200w", "/static/9edf85caf0d8031645dced6cdc60fb7d/d61c2/image-5.png 1800w", "/static/9edf85caf0d8031645dced6cdc60fb7d/97a96/image-5.png 2400w", "/static/9edf85caf0d8031645dced6cdc60fb7d/50f29/image-5.png 3038w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h5 {...{
          "id": "creating-a-resource",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#creating-a-resource",
            "aria-label": "creating a resource permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating a Resource`}</h5>
        <p>{`Before Integrating the Lambda Function with the API Gateway, we'll create a resource that we can Invoke the Lambda function from instead of the root resource `}<inlineCode parentName="p">{`/`}</inlineCode></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0333afa4da2543873c1095f04ea5d37a/d6331/image-6.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "95.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABYlAAAWJQFJUiTwAAACJUlEQVQ4y52UaXLaQBCFuf85co/8NqnEWFCWzKJ9QQLtSICEeKnXRDZmc5Kp6tIwM/1N9+seBnVdI0kSVFWFsixRVRu0hw5uEEEZT/A8UvCqavDDGIGlorSHMCwXw+ETFEXBfD7HeDzGarUCx2Cz2WA6ncIwDDHHtgVouQFGLwp+/PyF59EIpreG//odpfINYVziTVPlvGma4r9er0/A4/EoE357a5o9srzAKk6RZAWyvEScpFgnOTrcHvTbbrcYHNpWIirr/WkDQNM02NY1PNeFoeuwbQumaWA+nyHP0qsAOLquOwHbtj0tcOPP5n6/Fyi1FRkcR1KzLAtRFMmZw+EgkN7IYT3egf2tPZAFIoCCa5qGyWQiWqmqiuVyKc7Un/ZR0OoDeB4+gbvdDnEcCzQIArHFYoE0TWWffufGtU8RXgK5ztsZDcGMgK3BVG+NKw0vgXR0XVf6bDabSXTUkhHzN7XVdV2k4JznHwJ7KIvDOfVhSpSCjjTO+7WropwDCSGATvwy9a8GL/8S6Pu+pEmjjpdnH/bhLQ3DMBS9CGYf8pL/BnKdjU0QW8bmGz/r2cve/Ssg0zQNQyJcBoG0DvUsigJZlknE/wQMwhCm48D1fTFG2xtfj+d5n4ryGNh1OLoOmrGCztDRvmn0ulvl93+be1UumVoUIfM85GEo87IoJO3e+rdM4++7bcNvXVUoeLCuUfLxVxWyPEee5+JMHc+Na0z7Nx5LuEmieFC0AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create a resource",
                "title": "create a resource",
                "src": "/static/0333afa4da2543873c1095f04ea5d37a/d6331/image-6.png",
                "srcSet": ["/static/0333afa4da2543873c1095f04ea5d37a/5a46d/image-6.png 300w", "/static/0333afa4da2543873c1095f04ea5d37a/0a47e/image-6.png 600w", "/static/0333afa4da2543873c1095f04ea5d37a/d6331/image-6.png 702w"],
                "sizes": "(max-width: 702px) 100vw, 702px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Set the resource name and path as `}<inlineCode parentName="p">{`pokedex`}</inlineCode></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1ce96fd6fd710393adbba966d8dfb9f6/c7bb6/image-7.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "47.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABMklEQVQoz42S206EMBCG+/4Pok+gj6AXJu6NiQkhHAIspwJtiSy2tL+Z7kLUjSuTfEw7bYf5p2VVVaEoChRFBqUkjDHQWnuMnuGshXNuN6yua3DeoeUCXa8wDAJSCkg14kM0gDVwwP6Efd+Bc4E0HxFnCsYAnLe+QtxI9Jcx+pDM4zFHecxhtIZSCpxzzPPs11aWZdk8JSVPWGs3mHPnweelbzTO8xxRFCGOYyRJ4qF5lmVI03SLhWGIIAj8Gp05nU7nCn8bXRQdbprGVzsMg6fvez8XQqDrOu+llN4TVBCjQNu2XiJBwWmavLS95i5YBzDqAfWKoJJJ8tqjPVxdCpX642/fNtsbb3CxFtosOCQS988l7p5KvEQSbJX233O4kukczOLwXox4PFR4eC3xlil8ASdiCNxhP3ARAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create a resource",
                "title": "create a resource",
                "src": "/static/1ce96fd6fd710393adbba966d8dfb9f6/c1b63/image-7.png",
                "srcSet": ["/static/1ce96fd6fd710393adbba966d8dfb9f6/5a46d/image-7.png 300w", "/static/1ce96fd6fd710393adbba966d8dfb9f6/0a47e/image-7.png 600w", "/static/1ce96fd6fd710393adbba966d8dfb9f6/c1b63/image-7.png 1200w", "/static/1ce96fd6fd710393adbba966d8dfb9f6/d61c2/image-7.png 1800w", "/static/1ce96fd6fd710393adbba966d8dfb9f6/c7bb6/image-7.png 1986w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`After we create it, the resources will look like this:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "400px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/5dea6bbe9e7ee1fdee176a125b30eefd/e17e5/image-8.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "75.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAABaklEQVQ4y61SuW7CQBDd//8KOoQUkiZKg2iiFIYChFLEMXGB8YGDsSxOe73rF80YEyAGUjDS09tj5s2xK7A3rTWjKApGtSYjzrIMUko+z/McSilmOiOkacosyOGWkc96vUYURZhMJgiCANPplPdxHCNJEr4nCNd10e120ev1MBwOYZomRqMRDMPgNRlVQ4GDwQDNZhPtdhuNRgOtVgudTof9XC9A7H5AUKmUcTwew7ZtOI7D2S3Lgud5hyrDMOQ9+RITfN9nns1m8DwfcRRClPMqLrZbzUhmkudYByqKoJQuBQESVdzaOarHuobjRxRKF3h3lthJhXi1O7wq5Tnlcn3LhC4KJBsJmWtsUnkzoOroN8kpxHkAzeE72WC1zeDOl1gst/AXK8yT7SFhFVxbYV2Wl76NZ+MLT28WHl5NPDJ/om/65b+8Jvi3HSBXGmr/4Y8Djyv7l2CdwOU54rrgefZLuDlD3NnuLvgDIAiPqZxI0REAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create a resource",
                "title": "create a resource",
                "src": "/static/5dea6bbe9e7ee1fdee176a125b30eefd/e17e5/image-8.png",
                "srcSet": ["/static/5dea6bbe9e7ee1fdee176a125b30eefd/5a46d/image-8.png 300w", "/static/5dea6bbe9e7ee1fdee176a125b30eefd/e17e5/image-8.png 400w"],
                "sizes": "(max-width: 400px) 100vw, 400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h5 {...{
          "id": "creating-a-method-for-pokedex-resource",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#creating-a-method-for-pokedex-resource",
            "aria-label": "creating a method for pokedex resource permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating a method for /pokedex resource`}</h5>
        <p>{`We'll create a new `}<inlineCode parentName="p">{`GET`}</inlineCode>{` method for the `}<inlineCode parentName="p">{`pokedex`}</inlineCode>{` resource that Integrates with our Lambda function`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "710px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ee84b3749b1aceeb5ea40e37823f6885/7131f/image-9.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "97.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAABYlAAAWJQFJUiTwAAACKElEQVQ4y5VU2Y7aQBD0//9MPiBSnpJVsrAKAeQ1BtuAT06DDwzYpqLqZJBBy7Etjdoeddd0V/WMtlgskCQJ4jhGnmUo6xOsiY+Xn6/4/vILb7//YDSJEJo/kDvfYJg22q1XtFotDIdDtNttzOdz0E6nEzTDMODYNkzThO97qAg49tDp9vHW6aLX12F7C4T6V6z6X7CKUwyHJhzHwXQ6xWAwwHK5FLC6rqHhyg6HPdI0wzqOsU1S+d5ut4iTHe5ZURSoqgra/lgiL46s9z/gQQDYjq7rGBiG+Pd3HUHgS+JutxOvVp7nskfTWKpatP1+jyzLEASBtDMajUBaeIDneRcAajGeewKoSm4ClmUJimXbtvBEvlzXFb/ZbC7iaeSOB92skIBUPooirFYrIZ0VsxImX+dcAKrTmoDH4xFpmmI2m8k4sSpWzIM+soeATGSL5I3jxNZVy+qf3PZ6PdmjkMyTObwFyMVK6Ul4U1XGEISCqPibFTKQAOSLbSsR7hnn7yFgGIYiBD3nUvHXjH1aFAZQYXJIb1mWVNyM/ZTKrGa9XssMUmkKQj6v7VOAi/kcY8eB73kIg0ASlFAUozlCTwF6YYhxGMKNIkx9/3z9eB07nY6My1OiELCqa9Sui0O3i2oyQWkO7qrM3Lsqy8jMZthYFraeiyQMZU8eAeXz/Lz4f35trgHJBwMSghYFkt0OSZ7/exPjWO44v5uLe4pT7SPVbhkPe9T6XymiB6gCjTWzAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create a method",
                "title": "create a method",
                "src": "/static/ee84b3749b1aceeb5ea40e37823f6885/7131f/image-9.png",
                "srcSet": ["/static/ee84b3749b1aceeb5ea40e37823f6885/5a46d/image-9.png 300w", "/static/ee84b3749b1aceeb5ea40e37823f6885/0a47e/image-9.png 600w", "/static/ee84b3749b1aceeb5ea40e37823f6885/7131f/image-9.png 710w"],
                "sizes": "(max-width: 710px) 100vw, 710px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Select the Integration type as Lambda Function and `}<inlineCode parentName="p">{`pokedex`}</inlineCode>{` as the Lambda function:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1416e705229f99457e539e52fe1fe433/e4ba2/image-10.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "64.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVQ4y41TaY+DIBTk///Efltbo0AF8UBbj9nM25Co28OXvKAIwxyiusaj1Bq198jzHI/HA8da1/V0q9o7VFWF+/2OoijgvUfbttIh1DIuy3K6VR8j+r6XjRzJcBxHDMOAGONLxp9KbV8IQNlGlzCugfUdrNGonINzTg75ZoFKD6xpmoQlNw7jCGMtbrebzCVJCfAtwy36sYzR4msIQQ77BvZP8jFZY4xYkGUZns/nTu5HhgyB/nFMoRCAc5Q5z/OO3dG37ZyilMQksblcLrDWShBnpG6/7SQzDHpG4Lr2Akj/joG8YkgV8h9yIi3mZq01nKtQhwalNhhiRFrzrlld14lVKp1A7yg3y64o8it+Co9MB3ICl3y7crtQWDyJoAyDTe+WZf0DPHFDUii/Hxr7iAj4320AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "create a method",
                "title": "create a method",
                "src": "/static/1416e705229f99457e539e52fe1fe433/c1b63/image-10.png",
                "srcSet": ["/static/1416e705229f99457e539e52fe1fe433/5a46d/image-10.png 300w", "/static/1416e705229f99457e539e52fe1fe433/0a47e/image-10.png 600w", "/static/1416e705229f99457e539e52fe1fe433/c1b63/image-10.png 1200w", "/static/1416e705229f99457e539e52fe1fe433/e4ba2/image-10.png 1468w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h5 {...{
          "id": "testing-the-resource-endpoint",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#testing-the-resource-endpoint",
            "aria-label": "testing the resource endpoint permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Testing the resource endpoint`}</h5>
        <p>{`Once the method is created, the following page will show and we can adjust our configurations from it or test the API. It's really handy when we want to quickly debug an API that responds with an error:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/447440c0a0c252c467a16345c783d1b4/161ec/image-11.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "58.666666666666664%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABjklEQVQoz3VT227rIBDM/39f+3JUNQm2Y+IUanzlYpij3RjkRi3S+LJjht3Z9altWyiloLXGo+twv0tI2YLizjmklBBjZCRC2sGxhJQAYwxCCKB1moaeH7SZIeQ3zq2G/DKYxgGjlryR1modxL1H/TD8zUMPGJREcCvzfGBKOOnRcsCYAefLBUppLMuCeZ6xrguAxDxle75cUdU1xmnGFiNC8EWoCNLFWotxHFHXNZd6u93QNA3bQKUQTxuM6fkgEsqLOO89ixXBjNd15F75YzxytuEpmM1c5hlCXFFXFYQQkFJiXW0RKqL8vsd2ccpQa/VsCpXKwRDg/BPWOThn4e1aBLctwjkPu8N5D2cXIEU4v0GZmY84xb2LnTZ4+3fF+0cFITV7On53PCJcwWrxUXXMfTYKt0cPo++Im0OIQD87zp49pJQJZDh1OIvkcSCO7k1ToxKCrZn2yijrbdt+NiVv+M30bDjd6UDCxJ0OxT8SLGOThXLg2NEcy8+/TcGxkiJ4/L1ecezyX/xxsP8De46pXdYhIG4AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "testing resource",
                "title": "testing resource",
                "src": "/static/447440c0a0c252c467a16345c783d1b4/c1b63/image-11.png",
                "srcSet": ["/static/447440c0a0c252c467a16345c783d1b4/5a46d/image-11.png 300w", "/static/447440c0a0c252c467a16345c783d1b4/0a47e/image-11.png 600w", "/static/447440c0a0c252c467a16345c783d1b4/c1b63/image-11.png 1200w", "/static/447440c0a0c252c467a16345c783d1b4/d61c2/image-11.png 1800w", "/static/447440c0a0c252c467a16345c783d1b4/161ec/image-11.png 1840w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`If we tried to test the API by clicking TEST button and hit Test again in the next page, we'll notice that it throws the error: `}<inlineCode parentName="p">{`Execution failed due to configuration error: No match for output mapping and no default output mapping configured`}</inlineCode></p>
        <p>{`This is because we didn't specify what kind of response the method should respond with. Even though it shows `}<inlineCode parentName="p">{`HTTP Status: 200`}</inlineCode>{` under `}<inlineCode parentName="p">{`Method Response`}</inlineCode>{`, we still need to specify that.`}</p>
        <p>{`A quick click to `}<inlineCode parentName="p">{`Method Response`}</inlineCode>{` will open the following view where we can select the response type it should expect from the Integration response:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/74b3b3334d3917d3f471319ccc451fb6/f1f01/image-12.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "16.333333333333332%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAhElEQVQI122MSQ6DMBTFuP8ZgSUJQ5Lmk6kEcEWRuimWLL/Va1IuxFRYRUhBsJJwkvBesMbg/Qvn7Hcbs1By5om9VuCkSe8dbQN5O5CQ6Pqetu0YlGZQI2qcUdN8d5zR04Jx/lHnheZ6vTkp24E2gvUrzgeMRGysf5qw/Vyuxop2gckFPloF5jhdKL3SAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "testing resource",
                "title": "testing resource",
                "src": "/static/74b3b3334d3917d3f471319ccc451fb6/c1b63/image-12.png",
                "srcSet": ["/static/74b3b3334d3917d3f471319ccc451fb6/5a46d/image-12.png 300w", "/static/74b3b3334d3917d3f471319ccc451fb6/0a47e/image-12.png 600w", "/static/74b3b3334d3917d3f471319ccc451fb6/c1b63/image-12.png 1200w", "/static/74b3b3334d3917d3f471319ccc451fb6/d61c2/image-12.png 1800w", "/static/74b3b3334d3917d3f471319ccc451fb6/f1f01/image-12.png 2212w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`If we tried to test the API again, it'll work as expected:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/84b170314f129c4946755d049d39045f/2d912/image-13.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "36.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAAA1klEQVQoz4WR6wqEQAiF5/3fMOhHRdE9iu5Xl09wiGVhhYPMeNSjur7vZVkWOY5D9n33WNdVtm2TYRgkz3NFURTKJQYsD3ueR+HatpUsy6QsS4+maWSaJiWSGIahpGmqBf6Zo2sQBBJFkSoA8zzLfd9KoEgcxxpnGoyYwZR5hV3XqSrG+2UkUQgefhxH5drYb1zXJQ4CIJGP8zzVm5FcVZXUda2rIPZWaHn2rwXp/C0dYDRgx0mSeA/YKWAdrIlmHFOPwuO7qxWExLh4GxX/HptLo5AdfgBVNh3q/OowKAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "testing resource",
                "title": "testing resource",
                "src": "/static/84b170314f129c4946755d049d39045f/c1b63/image-13.png",
                "srcSet": ["/static/84b170314f129c4946755d049d39045f/5a46d/image-13.png 300w", "/static/84b170314f129c4946755d049d39045f/0a47e/image-13.png 600w", "/static/84b170314f129c4946755d049d39045f/c1b63/image-13.png 1200w", "/static/84b170314f129c4946755d049d39045f/2d912/image-13.png 1772w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h5 {...{
          "id": "deploying-the-api",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#deploying-the-api",
            "aria-label": "deploying the api permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Deploying the API`}</h5>
        <p>{`After creating a new API or applying changes to an exisitng one, we always have to deploy it.`}</p>
        <p>{`We deploy the API from the `}<inlineCode parentName="p">{`Actions`}</inlineCode>{`:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "728px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/12262bac99997f6949ae25767c0ad322/cecac/image-14.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "122.33333333333334%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAABYlAAAWJQFJUiTwAAACtUlEQVQ4y41Va3OiQBDk//+h+3ofc0m81FXiRUGQACKIIsjyWB72VW/EJIpctmpqH8z27vT0Dlqe59hsNkjTFLvdDmVRoJQN5gsLv+4fcf/4G7phwg82iMwJSpEiywRs24bruijLEmEYYrVaYb1eQxNCwDAMOI6DxWKB7XaLoqqhm0s8/XlWNtMX8PwA7vNPxFGAKNri4eEBpmmC+19fX3F3d4fJZAKtO+KqSVlhnySI4z2yLEN6OGCziTDW2rZVvSabDtu0RCJK5GWtwj2IHEVRwLQsLG0bc12H5/tY+T6CMEQURfB9H57nIUkSBUT/ruugcRIlOX7cW3hahAj3OZwgRtM0CF0XEYEsC5Ear7D2fcUZ+SJvPSC5PAPKukUiKmSFhChrxKlAU9eo/04hpy9oDB3y5Rm1Pge6bjDkM+DxeE2irCrFCb/QqTseQT/2XL80+nwJ+Xja8G5AVVWI41jJab/fq7AoqcPhgGv/9wtxD8daf6vLjwyBWnt7e1M2n88VZ1LKK1C25XKppDcISDCGwkwSjEDsOedDGALkOhN584b8SCCeSnnwtuy5NhQyLzEaMgH5tHgzcslXRLBb7YtsxgAty1JAQRCc3zyfm3pBaaqMvt8CZLjkjYQTlGOGznUexrfMAwnGPTcB6UDA7zbu+6LDS0BKg/pjiAyVGkQv8gHjvtGQeRq5mU6nypiQvlRRHkNGTm9mmafRyB2NmSZnpKHmGx8wgo7KhsJeeR6C9VpVlt1uO8rjf7NcNw0sx4HteXCY3ZMmGbqu66rK06/fOwpYUgJtC2GZyG0bpetCuA4kw5NSJa0H69tocVAcFgVyw4AwdAiChiGatj1zRj7lCZzGpJCmQUA6M8uJEB+WZUo+LGvsWdIoLc45ns1mqpJrQyL9NPmwkw5VoT1V7csxf1L/AN6rPD0952atAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "deploying the api",
                "title": "deploying the api",
                "src": "/static/12262bac99997f6949ae25767c0ad322/cecac/image-14.png",
                "srcSet": ["/static/12262bac99997f6949ae25767c0ad322/5a46d/image-14.png 300w", "/static/12262bac99997f6949ae25767c0ad322/0a47e/image-14.png 600w", "/static/12262bac99997f6949ae25767c0ad322/cecac/image-14.png 728w"],
                "sizes": "(max-width: 728px) 100vw, 728px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/edf130d552efc42f2ee203c499a84e40/21b4d/image-15.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "68%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACRklEQVQ4y4VSTW8SYRDeX6Tx4sWbJ8UWEltNz/4bYzyQaEq7GL70LkdDIDRFYmmLPdWExA8gLMsusN9f7MJjZvDFthp9kycz7+zMM887s9Kt23ewtZ3Gg4cpbKczyOzsYSudwaPMDlKZJ0hldn/ZfyC9i/TjPdy9dx9StVqFoijo9Xr4+u079N4RbHMOy7JhWdZ/YVsWDMNAEARoNBqQarUafN/HbDbjD66hIPA9jglQ8tX7TbiuiziO0W63ITWbTSwWC5imyR0N02Ziugsrml31KZd8kUen2+1CarVaHBwOhxgMBuj3+zwCTdPYjsdjjk2nU1Yh1AZhsFHveR4Tnp2dQzo+PuYLJa9WK1brOA7CMNwUiHgURWxvHvpO59NJ5zfhcrncEJMyUkWKyaczmUxYLSklAgLlUp2o7XROrhOKRFJHhOKpFNN1nZ9GJKSccgSZIDw9Pf1TIRWrqspENEeaL8XIktqJpkFVJ5hOZ1wjVG6WIgipiGZHy6HnEdlopMCYz5EsV1BUHWNVg2W7SJZAFC/hhzG8YIEk+QshdUmShJdAEEuhRVCzIIzghxGiRYxFnCBOEiZasbr1Ui4+dyHV63XenG3b/IP6nsezIgjfdRx4nrv2XRee68K0HMxNG0PNQuuLjvMfDupHH68T0pPtG6AYkbB11jlEONJNDFQDHy4UPM2e4Vn+EuX3DUjZbBaVSgXFYgEHhzJyBzLb/QMZuUMZ+Xx+gzdyDsViEaVSCeVyCe/ellEoFJB9tY/XORnPX7zET+6g6s+pLVCsAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "deploying the api",
                "title": "deploying the api",
                "src": "/static/edf130d552efc42f2ee203c499a84e40/c1b63/image-15.png",
                "srcSet": ["/static/edf130d552efc42f2ee203c499a84e40/5a46d/image-15.png 300w", "/static/edf130d552efc42f2ee203c499a84e40/0a47e/image-15.png 600w", "/static/edf130d552efc42f2ee203c499a84e40/c1b63/image-15.png 1200w", "/static/edf130d552efc42f2ee203c499a84e40/21b4d/image-15.png 1280w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h5 {...{
          "id": "stages",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#stages",
            "aria-label": "stages permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Stages`}</h5>
        <p>{`Stages refer to different versions of an API that can be created, managed, and deployed separately within the API Gateway service. Whenever we deploy an API, we can choose whether we want to overwrite an existing stage or creata a new one.`}</p>
        <p>{`It's helpful if we want to:`}</p>
        <ol>
          <li parentName="ol">{`Test out a version of an API without affecting the production one`}</li>
          <li parentName="ol">{`Deploy the API to different regions`}</li>
          <li parentName="ol">{`Apply different security settings to different APIs`}</li>
        </ol>
        <h5 {...{
          "id": "invoking-the-api-from-a-public-url",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#invoking-the-api-from-a-public-url",
            "aria-label": "invoking the api from a public url permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Invoking the API from a public URL`}</h5>
        <p>{`After we deploy it, we can now invoke the API from the provided Invoke URL that we can find by going to Stages -> prod -> `}<inlineCode parentName="p">{`/`}</inlineCode>{` -> GET:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/21c6715cf51f496826f551b056f5b7d6/5c4aa/image-16.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "24%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA1klEQVQY022QCU7DMBBFc/8bcBlOgVQKQgiKitNstuMl3vJQkqZFiC89+Wu+PJqZKsZIzpkQI8YYzJR5eDzx9NHRDgN1J/kSFzo1YmPGhI27TxtTwoZM1fU9xlhCCIwuoEYDFPSUeB88n9Jz0pFjYzgIxUtjea5HDkKv/rV1K8fG8tZ7qjQ5KJF5nhlNppeWnBPee7TW69RSaUQrOYsL4vuMlHKtL5v9VTXJmjK2lJz4J78prSexKKVwzt3qyyDbe23Y9cOvYGPxpZQ783z7uGvP5mu28wNI/4QbkJeEYQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "invoking the api",
                "title": "invoking the api",
                "src": "/static/21c6715cf51f496826f551b056f5b7d6/c1b63/image-16.png",
                "srcSet": ["/static/21c6715cf51f496826f551b056f5b7d6/5a46d/image-16.png 300w", "/static/21c6715cf51f496826f551b056f5b7d6/0a47e/image-16.png 600w", "/static/21c6715cf51f496826f551b056f5b7d6/c1b63/image-16.png 1200w", "/static/21c6715cf51f496826f551b056f5b7d6/d61c2/image-16.png 1800w", "/static/21c6715cf51f496826f551b056f5b7d6/97a96/image-16.png 2400w", "/static/21c6715cf51f496826f551b056f5b7d6/5c4aa/image-16.png 2492w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`For our example, it's `}<a parentName="p" {...{
            "href": "https://mn9hd5qc51.execute-api.us-east-1.amazonaws.com/Prod/pokedex"
          }}>{`https://mn9hd5qc51.execute-api.us-east-1.amazonaws.com/Prod/pokedex`}</a>{` try to open this URL!`}</p>
        <h4 {...{
          "id": "creating-a-rest-api-for-an-aws-service-dynamodb",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#creating-a-rest-api-for-an-aws-service-dynamodb",
            "aria-label": "creating a rest api for an aws service dynamodb permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Creating a REST API for an AWS Service (DynamoDB)`}</h4>
        <p>{`AWS services do not typically provide an API for performing operations. However, you can use API Gateway to create an API endpoint that allows you to access an AWS service through an API call. This enables you to perform operations on the service using an API, rather than having to interact with the service directly.`}</p>
        <p><a parentName="p" {...{
            "href": "https://aws.amazon.com/blogs/compute/using-amazon-api-gateway-as-a-proxy-for-dynamodb/"
          }}>{`Follow this guide to create a DynamoDB and the REST API resources and methods`}</a>{` but generally, learn about what kind of Actions or APIs does the service has. In the case of DynamoDB, we'll use this `}<a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/HowItWorks.API.html"
          }}>{`document`}</a>{`.`}</p>
        <p>{`After following the steps mentioned in it, we'll have these two endpoints:`}</p>
        <ol>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item"
            }}>{`POST Endpoint`}</a>{` for creating a new Pokemon`}</li>
          <li parentName="ol"><a parentName="li" {...{
              "href": "https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item/%7Bpokemon%7D"
            }}>{`GET Endpoint`}</a>{` that takes a Pokemon's name path parameter`}
            <ol parentName="li">
              <li parentName="ol">{`E.g, `}<a parentName="li" {...{
                  "href": "https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item/pikachu"
                }}>{`https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item/pikachu`}</a></li>
            </ol>
          </li>
        </ol>
        <p>{`For the `}<inlineCode parentName="p">{`POST`}</inlineCode>{` Endpoint, if we send a request to create a Pokemon named `}<inlineCode parentName="p">{`friesmeat`}</inlineCode>{` and has the age `}<inlineCode parentName="p">{`3`}</inlineCode>{`, we'll send the following:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`curl -X POST \\
  'https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item' \\
  --header 'Accept: */*' \\
  --header 'User-Agent: Thunder Client (https://www.thunderclient.com)' \\
  --header 'Content-Type: application/json' \\
  --data-raw '{
  "pokemonName": "friesmeat",
  "age": "3"
}'
`}</code></pre>
        <p>{`And it responds with an empty object `}<inlineCode parentName="p">{`{}`}</inlineCode>{` that indicates the operation was successful. `}<a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_PutItem.html#API_PutItem_ResponseSyntax"
          }}>{`The action `}<inlineCode parentName="a">{`PutItem`}</inlineCode>{` doesn't return the created object`}</a>{` by spec.`}</p>
        <p>{`For the `}<inlineCode parentName="p">{`GET`}</inlineCode>{` Endpoint, if we send a request to get the created Pokemon `}<inlineCode parentName="p">{`friesmeat`}</inlineCode>{`:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`curl -X GET \\
  'https://aqeebamk5e.execute-api.us-east-1.amazonaws.com/prod/item/friesmeat' \\
  --header 'Accept: */*' \\
  --header 'User-Agent: Thunder Client (https://www.thunderclient.com)'
`}</code></pre>
        <p>{`It'll respond with the item:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "pokemons": [
    {
      "itemId": "f8630ede-a6e4-4528-99aa-933b42f963ae",
      "pokemonName": "friesmeat",
      "age": "3"
    }
  ]
}
`}</code></pre>
        <h5 {...{
          "id": "mapping-templates",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h5" {...{
            "href": "#mapping-templates",
            "aria-label": "mapping templates permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Mapping templates`}</h5>
        <p>{`A mapping template is a way to define how incoming data from an HTTP request should be transformed before it is passed to a backend service. This can include changing the format of the data, extracting specific values, or adding default values.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "TableName": "Items", // default value
  "Item": {
    "itemId": {
      "S": "$context.requestId"
    },
    "pokemonName": {
      "S": "$input.path('$.pokemonName')"
    },
    "age": {
      "S": "$input.path('$.age')"
    }
  }
}
`}</code></pre>
        <p>{`In the example given, the mapping template is used to ensure that requests sent to the DynamoDB integration have the correct format.`}</p>
        <p>{`The template sets the "TableName" to "Items" and defines the structure of the "Item" object, which includes "itemId", "pokemonName", and "age" attributes.`}</p>
        <p>{`Using this mapping template, the user is only required to provide the necessary information to create the "Item" object, rather than manually constructing the entire request body. It could also be used to map the Integration response to a format that's easier for the user to work with.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`#set($inputRoot = $input.path('$'))
{
    "pokemons": [
        #foreach($elem in $inputRoot.Items) {
            "itemId": "$elem.itemId.S",
            "pokemonName": "$elem.pokemonName.S",
            "age": "$elem.age.S"
        }#if($foreach.hasNext),#end
    #end
    ]
}
`}</code></pre>
        <p>{`This mapping template is used to define the format of the response that is sent back to the user when a request is made to DynamoDB integration.`}</p>
        <p><a parentName="p" {...{
            "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/rest-api-develop-models-mapping-examples.html"
          }}>{`Learn about the mapping template syntax through these examples`}</a></p>
        <h3 {...{
          "id": "advanced-api-gateway-usage",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#advanced-api-gateway-usage",
            "aria-label": "advanced api gateway usage permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Advanced API Gateway usage`}</h3>
        <h4 {...{
          "id": "logging-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#logging-1",
            "aria-label": "logging 1 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Logging`}</h4>
        <p>{`In order to enable logging for an API in API Gateway, you need to create an IAM role that allows API Gateway to write logs to Amazon CloudWatch.`}</p>
        <p>{`You can follow this guide provided by AWS to integrate logging in API Gateway: `}<a parentName="p" {...{
            "href": "https://aws.amazon.com/premiumsupport/knowledge-center/api-gateway-cloudwatch-logs/"
          }}>{`https://aws.amazon.com/premiumsupport/knowledge-center/api-gateway-cloudwatch-logs/`}</a></p>
        <p>{`When sending a GET request, our logs will show details about the request, such as the request method, path, headers, and response status. An example of how the logs might look like:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/abb8a3f9789ac4e4ef114216604cef3b/beade/image-17.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJElEQVQoz4VSW27CMBDM/c9S9RxcgArIA4eI+O31A1X9m2oXqKJWqB8jx9717Hgm3WVZME4TlFJQasayLDifFdQ8Y5rOAq6t6wprLaxzMNbCOQfvvax8zvUQIjo+ZJKYEmJMyESopaBskHNGrRWttZfgeiJCxxvvPPpxxOocqBRQzgIm40ZG+TXkFYQwxoir1jAxwIQ7dPBI+a/afwmJSDxIKd3l14pba4Ltc7Zorf5YsLVCCFmd1loMZnJu8CGIn0/T2Wz2mIdyT3jUeX3urTEgSug4kGEY0fcDLpdFLvM3J3s4HLHff0jSs6Q+yZ/AL/I+yDD3SN1GQv38uqfMCtdVSxPvT6deLh+ORyHlAcN8hTYGxhhRyhCliRDMFbR7R9m94RsT2lgHl8Kg3wAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "logs",
                "title": "logs",
                "src": "/static/abb8a3f9789ac4e4ef114216604cef3b/c1b63/image-17.png",
                "srcSet": ["/static/abb8a3f9789ac4e4ef114216604cef3b/5a46d/image-17.png 300w", "/static/abb8a3f9789ac4e4ef114216604cef3b/0a47e/image-17.png 600w", "/static/abb8a3f9789ac4e4ef114216604cef3b/c1b63/image-17.png 1200w", "/static/abb8a3f9789ac4e4ef114216604cef3b/d61c2/image-17.png 1800w", "/static/abb8a3f9789ac4e4ef114216604cef3b/beade/image-17.png 2266w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "authentication-1",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#authentication-1",
            "aria-label": "authentication 1 permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Authentication`}</h4>
        <p>{`In an API, authentication can be implemented in two ways:`}</p>
        <ol>
          <li parentName="ol">{`Using a Lambda function: You can create a Lambda function that handles the authentication process. This function would act as a middleware (C0D3) that verifies whether the user is authorized to execute the resolver or not.`}</li>
          <li parentName="ol">{`Using a Cognito user pool: AWS Cognito User Pools provide an easy way to handle user sign-up, sign-in, and access control. `}<em parentName="li">{`(Cognito totally went over my head)`}</em></li>
        </ol>
        <h4 {...{
          "id": "rate-limiting--request-throttling",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#rate-limiting--request-throttling",
            "aria-label": "rate limiting  request throttling permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Rate Limiting / Request Throttling`}</h4>
        <p>{`API Gateway provides a feature called "Usage Plans" that allows you to specify rate limits and quotas for requests to an API. This feature can be used to control the number of requests per second, the total number of requests, and to track the usage of API keys.`}</p>
        <p>{`You can configure usage plans for an entire stage or for individual methods. To do this, go to the API Gateway console, select an API, then go to the "Usage Plans" section. There, you can specify the rate limits and quotas for the API, and create API keys for developers to use. This allows you to keep track of how much quota each developer is using, and to prevent excessive usage that might affect the performance of your API.`}</p>
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <ul>
          <li parentName="ul">{`AWS API Gateway`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/welcome.html"
                }}>{`Main docs`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-vs-rest.html"
                }}>{`REST API VS HTTP API`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/models-mappings.html#models-mappings-mappings"
                }}>{`Mapping templates & Models`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://snyk.io/blog/how-to-build-secure-api-gateway-node-js/"
            }}>{`Express.js API Gateway`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.any-api.com/amazonaws_com/apigateway/docs/Definitions/MethodResponse"
            }}>{`Method response type`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      